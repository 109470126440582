<template>
  <base-layout>
    <avatar/>
    <div class="name">KOOL HOUSE</div>
    <button-list/>
    <div class="something">
      <div class="clause">{{clause}}</div>
      <div class="clause">——{{author}}</div>
    </div>
  </base-layout>
</template>

<script>
import Avatar from '@/components/Avatar'
import ButtonList from '@/components/ButtonList'
import BaseLayout from '@/components/BaseLayout'

export default {
  name: 'Home',
  components: {
    Avatar,
    ButtonList,
    BaseLayout
  },
  data() {
    return {
      clause: '每日一句',
      author: '作者',
      timer: ''
    }
  },
  created() {
    this.getClause()
  },
  mounted() {
    this.timer = setInterval(this.getClause, 8000)
  },
  methods: {
    getClause() {
      this.$api.get('https://saying.api.azwcl.com/saying/get').then(res => {
        this.clause = res.data.data.content
        this.author = res.data.data.author
      })
    }
  }
}
</script>

<style lang="sass" scoped>
@import "src/assets/style/global"
.name
  margin-top: 30px
  font-size: 2.5rem
  color: white
  font-style: italic
.something
  max-width: 1000px
  margin: 150px auto 0 auto
  .clause
    margin-top: 20px
    color: white
    font-size: 1rem
    font-style: italic
</style>
